import {
  faCheck,
  faTimes,
  faCameraCircle,
  faCameraRotate,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "@sokigo/components-react-bootstrap";
import {useState, useEffect, Fragment} from "react";

export function CameraModal({show, onClose, onSave}) {
  const t = useAppTranslation();
  const [saving, setSaving] = useState(false);
  const [disableOk, setdisableOk] = useState(true);
  const [image, setImage] = useState(false);
  const [media, setMedia] = useState(null);
  const [cameraType, setCameraType] = useState("user");
  const [errorMessage, setDeviceErrorMessage] = useState("");
  useEffect(() => {
    startCamera("user");
  }, []);

  async function save() {
    setSaving(true);
    setdisableOk(true);
    try {
      const file = new File([image], "test.png", {
        type: "image/png",
        lastModified: new Date().getTime(),
      });
      await onSave(file);
    } finally {
      setSaving(false);
      setdisableOk(false);
      closeModal();
    }
  }

  function rotateCamera() {
    const type = cameraType === "user" ? "environment" : "user";
    setCameraType(type);
    stopCamera();
    startCamera(type);
  }

  function startCamera(type) {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: {
            exact: type,
          },
        },
      })
      .then((stream) => {
        document.querySelector("video").srcObject = stream;
        const track = stream.getVideoTracks()[0];
        const imageCapture = new ImageCapture(track);
        setMedia({imageCapture, stream});
      })
      .catch((error) => setDeviceErrorMessage(error.message));
  }

  function stopCamera() {
    if (media && media.stream !== null) {
      media.stream.getTracks().map(function (val) {
        val.stop();
      });
    }
  }
  function onTakePhotoButtonClick() {
    if (media.imageCapture !== null) {
      media.imageCapture
        .takePhoto()
        .then((blob) => {
          setImage(blob);
          return createImageBitmap(blob);
        })
        .then((imageBitmap) => {
          const canvas = document.querySelector("#takePhotoCanvas");
          drawCanvas(canvas, imageBitmap);
          setdisableOk(false);
        })
        .catch(() => {
          return;
        });
    }
  }

  function drawCanvas(canvas, img) {
    canvas.width = getComputedStyle(canvas).width.split("px")[0];
    canvas.height = getComputedStyle(canvas).height.split("px")[0];
    const ratio = Math.min(
      canvas.width / img.width,
      canvas.height / img.height
    );
    const x = (canvas.width - img.width * ratio) / 2;
    const y = (canvas.height - img.height * ratio) / 2;
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
    canvas
      .getContext("2d")
      .drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        x,
        y,
        img.width * ratio,
        img.height * ratio
      );
  }
  function closeModal() {
    stopCamera();
    onClose();
  }
  return (
    <Modal
      size="fill"
      show={show}
      onClose={closeModal}
      static
      scrollable={true}
    >
      <ModalBody>
        {!errorMessage ? (
          <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
            <div>
              <video style={{width: "100%"}} autoPlay></video>
              {media ? (
                <Fragment>
                  <Button
                    onClick={rotateCamera}
                    kind="primary"
                    style={{
                      height: "100px",
                      width: "15%",
                      float: "left",
                      textAlign: "center",
                    }}
                  >
                    <Icon icon={faCameraRotate} size={4} />
                  </Button>
                  <Button
                    onClick={onTakePhotoButtonClick}
                    kind="primary"
                    style={{
                      height: "100px",
                      width: "84%",
                      float: "left",
                      marginLeft: "1%",
                      textAlign: "center",
                    }}
                  >
                    <Icon icon={faCameraCircle} size={4} />
                  </Button>
                </Fragment>
              ) : (
                <Spinner fullscreen inline />
              )}
            </div>
            <canvas id="takePhotoCanvas" style={{width: "1000px"}}></canvas>
          </div>
        ) : (
          <span>{errorMessage}</span>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          onClick={closeModal}
          className="mr-2"
          icon={faTimes}
          disabled={saving}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={save}
          icon={faCheck}
          disabled={disableOk}
          kind="primary"
        >
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
