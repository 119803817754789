import {AccessDenied} from "@sokigo-sbwebb/default-components";
import {AppTranslationProvider} from "@sokigo-sbwebb/default-i18n";
import {ServerStateProvider} from "../ServerStateProvider";
import {CaseWorkersProvider} from "../services/useCaseWorkers";
import {GeoLocationProvider} from "../services/useGeoLocation";
import {VisitFormsProvider} from "../services/useVisitForms";
import {MapSettingsProvider} from "../services/useMapSettings";
import {PhraseGroupProvider} from "../services/usePhraseGroups";
import {DeviceVisitsProvider} from "../sw-comms/device-inspections-repo/useDeviceVisits";
import {useCurrentEcosUserId} from "../useCurrentEcosUserId";
import {InspectionMainRoute} from "./InspectionMainRoute";
import {TabJobTimers} from "../../../sw-alt/TabJobTimers";
import {Spinner} from "@sokigo/components-react-bootstrap";

export function InspectionGui(props) {
  const ecosUserId = useCurrentEcosUserId();

  if (ecosUserId === undefined) {
    return (
      <div className="d-inline-block m-3">
        <Spinner />
      </div>
    );
  }
  if (ecosUserId === null) {
    // View when access been denied
    return <AccessDenied />;
  }

  return (
    <TabJobTimers>
      <AppTranslationProvider ns={["common", "inspection", "validation"]}>
        <ServerStateProvider key={ecosUserId}>
          <DeviceVisitsProvider key={ecosUserId}>
            <MapSettingsProvider>
              <VisitFormsProvider>
                <PhraseGroupProvider>
                  <CaseWorkersProvider>
                    <GeoLocationProvider>
                      <InspectionMainRoute {...props} />
                    </GeoLocationProvider>
                  </CaseWorkersProvider>
                </PhraseGroupProvider>
              </VisitFormsProvider>
            </MapSettingsProvider>
          </DeviceVisitsProvider>
        </ServerStateProvider>
      </AppTranslationProvider>
    </TabJobTimers>
  );
}
