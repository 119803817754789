import {Fragment} from "react";
import {
  Button,
  Select,
  Switch,
  TableCell,
  TableRow,
  Control,
  Tooltip,
} from "@sokigo/components-react-bootstrap";
import {v4 as uuid} from "uuid";
import {
  faTrashCan,
  faPlus,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {ControlCause} from "../../Common/ControlCause";
import {
  useFoodAssessmentValidation,
  useFoodFacilityTypeValidation,
  useObligatoryBevaWhen92ControlValidation,
  useFoodControlAreas24Validation,
} from "../foodInspection24Validator/useFoodInspection24Validation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function ControlItem({row, onChange, onRemove}) {
  const t = useAppTranslation();

  const validationErrors = [];

  const hasObligatoryBevaWhen92ControlValid =
    useObligatoryBevaWhen92ControlValidation(row.control.id);
  const foodFacilityTypesValid = useFoodFacilityTypeValidation(row.control.id);
  const hasAnyAssessmentsValid = useFoodAssessmentValidation(row.control.id);
  const controlAreasValid = useFoodControlAreas24Validation(row.control.id);

  if (!hasObligatoryBevaWhen92ControlValid) {
    validationErrors.push(t("missingObligatoryBevaWhen92Control"));
  }
  if (!foodFacilityTypesValid) {
    validationErrors.push(t("foodFacilityTypeNotValidForInspection"));
  }
  if (!hasAnyAssessmentsValid) {
    validationErrors.push(t("missingAssessmentErrorMessage"));
  }
  if (!controlAreasValid) {
    validationErrors.push(t("missingLawSection"));
  }

  function onControlChange(v) {
    const updated = {...row.control, ...v};
    if (updated.cause !== ControlCause.Planned) {
      delete updated.control92;
    }
    onChange(updated);
  }

  function onAdd(foodFacilityTypeId) {
    onChange({id: uuid(), foodFacilityTypeId: foodFacilityTypeId});
  }

  function isFirstRow(id) {
    return row.firstControlId === id;
  }

  function getControlReasons() {
    const selectedReasonsId =
      row.selectedControlReasons && row.selectedControlReasons.map((x) => x.id);
    const selectedCause =
      row.selectedControlReasons &&
      row.selectedControlReasons.find((x) => x.id === row.control.cause);
    //removing already selected reasons from list and adding currently selected one so it can be showed in ddl
    //when follow up is chosen you're not allowed to change it to a standard control and vice versa
    const availableControlReasons = selectedCause ? [selectedCause] : [];
    if (!selectedCause || selectedCause.id !== ControlCause.FollowUp) {
      availableControlReasons.push(
        ...row.foodFacilityType.controlReason.filter(
          (x) =>
            !selectedReasonsId.includes(x.id) &&
            (!selectedCause ||
              (selectedCause.id !== ControlCause.FollowUp &&
                x.id !== ControlCause.FollowUp))
        )
      );
    }

    const any =
      row.foodFacilityType.controlReason
        .filter((x) => !selectedReasonsId.includes(x.id))
        .concat([selectedCause])
        .filter((x) => x !== undefined).length > 1;

    return {
      availableControlReasons,
      any: any,
    };
  }

  function getAnnouncedValue() {
    if (row.control.announced === undefined || row.control.announced === null)
      return undefined;
    else return row.control.announced ? 1 : 2;
  }

  return (
    <Fragment>
      <TableRow
        key={row.key}
        row={row}
        style={{height: "120px", position: "relative"}}
      >
        <TableCell>
          {isFirstRow(row.control.id) && row.foodFacilityType.name}
        </TableCell>
        <TableCell>
          {isFirstRow(row.control.id) && row.foodFacilityType.startDate}
        </TableCell>
        <TableCell>
          {isFirstRow(row.control.id) && row.foodFacilityType.endDate}
        </TableCell>
        <TableCell>
          {validationErrors.length > 0 && (
            <Tooltip
              text={validationErrors.map((err) => (
                <>
                  {err}
                  <br />
                </>
              ))}
            >
              <div className="d-inline-block">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="text-error"
                />
              </div>
            </Tooltip>
          )}
        </TableCell>
        <TableCell style={row.control.cause ? {} : {paddingTop: "16px"}}>
          <Control errors={row.control.cause ? [] : [t("mandatory")]}>
            <Select
              placeholder={t("selectControlCause")}
              options={getControlReasons().availableControlReasons}
              value={row.control.cause}
              getOptionValue={(x) => x.id}
              getOptionKey={(x) => x.id}
              getOptionText={(x) => x.text}
              onChange={(v) => {
                onControlChange({cause: v});
              }}
            />
          </Control>
        </TableCell>
        {!row.control.cause && <TableCell colSpan={6}></TableCell>}
        {row.control.cause && (
          <Fragment>
            <TableCell style={getAnnouncedValue() ? {} : {paddingTop: "16px"}}>
              <Control errors={getAnnouncedValue() ? [] : [t("mandatory")]}>
                <Select
                  placeholder={t("selectControlCause")}
                  options={[
                    {id: 1, text: "Föranmäld"},
                    {id: 2, text: "Oanmäld"},
                  ]}
                  value={getAnnouncedValue()}
                  getOptionValue={(x) => x.id}
                  getOptionKey={(x) => x.id}
                  getOptionText={(x) => x.text}
                  onChange={(v) => {
                    onControlChange({announced: v === 1 ? true : false});
                  }}
                />
              </Control>
            </TableCell>
            <TableCell>
              {row.control.cause === ControlCause.Planned && (
                <Switch
                  value={row.control.control92}
                  checked={row.control.control92}
                  kind="success"
                  onChange={(v) => {
                    onControlChange({control92: v});
                  }}
                />
              )}
            </TableCell>
            <TableCell>
              <Switch
                value={row.control.onSite}
                checked={row.control.onSite}
                kind="success"
                onChange={(v) => {
                  onControlChange({onSite: v});
                }}
              />
            </TableCell>
            <TableCell>
              <Switch
                value={row.control.sample}
                checked={row.control.sample}
                kind="success"
                onChange={(v) => {
                  onControlChange({sample: v});
                }}
              />
            </TableCell>
            <TableCell>
              <Switch
                value={row.control.revision}
                kind="success"
                checked={row.control.revision}
                onChange={(v) => {
                  onControlChange({revision: v});
                }}
              />
            </TableCell>
            <TableCell>
              <Button
                kind="ghost"
                icon={faTrashCan}
                value={row.control.id}
                onClick={(e) => {
                  onRemove(e.currentTarget.value);
                }}
              />
            </TableCell>
          </Fragment>
        )}
      </TableRow>
      {row.control.id === row.lastControl.id &&
        row.lastControl.cause &&
        getControlReasons().any && (
          <TableRow row={{}}>
            <TableCell colSpan={4}></TableCell>
            <TableCell colSpan={7} className="py-1 pb-3">
              <div style={{paddingTop: "1em"}}>
                <Button
                  kind={"primary"}
                  icon={faPlus}
                  onClick={() => {
                    onAdd(row.control.foodFacilityTypeId);
                  }}
                >
                  {t("addNewControl")}
                </Button>
              </div>
            </TableCell>
          </TableRow>
        )}
    </Fragment>
  );
}
