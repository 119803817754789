import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Validation} from "@sokigo-sbwebb/validation";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@sokigo/components-react-bootstrap";
import {useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {useServerState} from "../../../../ServerStateProvider";
import {EditVisitForm} from "./EditVisitForm";
import useToggleFoodInspectionControlAreas from "./VisitFormEditor/Controls/useToggleFoodInspectionControlAreas";
import {SaveAsType} from "./VisitFormEditor/SaveAsType";
import VisitFormSchema from "../VisitFormSchema.json";

const newVisitForm = {
  id: null,
  title: "",
  subtitle: "",
  sections: {
    presentParties: false,
    controlAreas: false,
    notes: false,
    documents: false,
    controlInformation: false,
  },
  emailDispatch: {
    allow: false,
    subject: "",
    body: "",
  },
  notes: [],
  controlAreas: [],
  facilityTypeIds: [],
  reportTemplateIds: [],
  saveAsType: SaveAsType.GenericInspection,
  controlResponseFormat: 0,
  disabled: true,
};

export const EditVisitFormModal = ({
  show,
  onClose,
  visitFormId,
  visitForms,
}) => {
  const t = useAppTranslation();
  const [saving, setSaving] = useState(false);
  const {
    baseData: {foodInspectionControlAreas},
  } = useServerState();
  const {addErrorNotification} = useNotifications();
  const {addVisitForm, updateVisitForm} = useServerState();
  const [visitForm, setVisitForm] = useState(
    visitFormId
      ? visitForms.find((x) => x.id === visitFormId)
      : {...newVisitForm, id: uuid()}
  );

  const [
    selectedControlAreas,
    toggleControlAreaQuestion,
    toggleAllControlAreaQuestions,
    clear,
  ] = useToggleFoodInspectionControlAreas(
    foodInspectionControlAreas.map((fica) => ({
      ...fica,
      questions: fica.questions.map((q) => q.id),
    })),
    visitForm.foodInspectionControlAreas
  );

  const onSave = async () => {
    setSaving(true);
    try {
      if (visitFormId) {
        await updateVisitForm(visitFormId, {
          ...visitForm,
          foodInspectionControlAreas: selectedControlAreas,
        });
      } else {
        await addVisitForm({
          ...visitForm,
          foodInspectionControlAreas: selectedControlAreas,
        });
      }
      // addSuccessNotification(t("visitFormSaved"));
      setVisitForm({...newVisitForm, id: uuid()});
    } catch (err) {
      addErrorNotification(t("visitFormCouldNotBeSaved"));
      throw err;
    } finally {
      setSaving(false);
      onClose();
      clear();
    }
  };

  const validation = useMemo(() => new Validation(VisitFormSchema), []);
  const validationErrors = validation.validate(visitForm, t);

  const onCancel = () => {
    setVisitForm({...newVisitForm, id: uuid()});
    onClose();
    clear();
  };

  return (
    <Modal show={show} static onClose={onCancel} size="xl" scrollable={true}>
      <ModalHeader>
        {visitFormId
          ? `${t("editVisitForm")} - ${visitForm.title}`
          : t("createVisitForm")}
      </ModalHeader>
      <ModalBody className="pl-md-0">
        <fieldset disabled={saving}>
          <EditVisitForm
            visitForm={visitForm}
            validationErrors={validationErrors}
            onChange={setVisitForm}
            selectedControlAreas={selectedControlAreas}
            toggleControlAreaQuestion={toggleControlAreaQuestion}
            toggleAllControlAreaQuestions={toggleAllControlAreaQuestions}
          />
        </fieldset>
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          className="mr-2"
          onClick={onCancel}
          disabled={saving}
          icon={faTimes}
        >
          {t("cancel")}
        </Button>
        <Button
          kind="primary"
          onClick={onSave}
          disabled={(validationErrors && validationErrors.length > 0) || saving}
          icon={faCheck}
        >
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
