import {
  faCheck,
  faEdit,
  faPlus,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {XTreeView} from "@sokigo-sbwebb/default-components";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  ButtonSwitch,
  ButtonSwitchOption,
  Container,
  Control,
  Select,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TimePicker,
} from "@sokigo/components-react-bootstrap";
import {useCallback, useMemo, useState} from "react";
import {useCurrentVisit} from "../../../InspectionVisitRoute";
import {v4 as uuid} from "uuid";
import {useCompletingInformationProvider} from "../CompletingInformationTab";
import {useCurrentEcosUserId} from "../../../../../useCurrentEcosUserId";

function TimeRegistrationModal({onClose, onChange, value, selectedId}) {
  const t = useAppTranslation();
  const {notBillableArticle} = useCompletingInformationProvider();
  const isEditMode = selectedId ? true : false;
  const currentEcosUserId = useCurrentEcosUserId();
  const errorBorderStyle = {border: "solid 1px #cf303e"};
  const errorMessageStyle = {
    marginTop: "0.25rem",
    fontSize: "0.75rem",
    color: "#cf303e",
    letterSpacing: "0.1px",
  };

  const [localValue, setLocalValue] = useState(
    selectedId
      ? {...value.spentTimeRegistration.find((x) => x.id === selectedId)}
      : {
          id: uuid(),
          travelTime: undefined,
          plannedTime: undefined,
          articleId: undefined,
          performedByCaseWorkerId: currentEcosUserId,
        }
  );

  const {
    visit: {
      visitMetadata: {billableArticles},
      baseDataSnapshot: {caseWorkers, timeTypes, timeTypeMandatory},
    },
  } = useCurrentVisit();

  const getTimeTypesTree = useCallback((timeTypes) => {
    return timeTypes.map((t) => ({
      key: t.id,
      label: t.name,
      children: getTimeTypesTree(t.children),
      childMandatory: t.childMandatory,
    }));
  }, []);

  const mappedValue = useMemo(() => {
    if (!localValue.timeTypeId) {
      return [];
    }

    function treeContainsKey(node, key) {
      return (
        node.key === key ||
        node.children.some((child) => treeContainsKey(child, key))
      );
    }

    function getSubTreeWithKey(node, key) {
      if (node.key === key) {
        return {...node, children: []};
      }
      return {
        ...node,
        children: node.children
          .filter((child) => treeContainsKey(child, key))
          .map((child) => getSubTreeWithKey(child, key)),
      };
    }

    const options = getTimeTypesTree(timeTypes);
    const value = options
      .filter((child) => treeContainsKey(child, localValue.timeTypeId))
      .map((child) => getSubTreeWithKey(child, localValue.timeTypeId));

    return value;
  }, [getTimeTypesTree, localValue.timeTypeId, timeTypes]);

  const isTimeTypeMandatory = useMemo(() => {
    let leafNode;
    let child;
    const fullTimeTree = getTimeTypesTree(timeTypes);

    function findLeaf(array) {
      array.forEach(function (t) {
        leafNode = t;
        if (Array.isArray(t.children)) {
          findLeaf(t.children);
        }
      });
    }

    function findChild(array) {
      if (array !== undefined) {
        array.forEach(function (t) {
          child = t;
          if (Array.isArray(t.children)) {
            findChild(t.children);
          }
        });
      }
    }
    const currentKey = mappedValue.map((x) => x.key);
    const pickedTimeWithTrueChildren = fullTimeTree.filter(
      (x) => x.key === currentKey[0]
    );
    findLeaf(mappedValue);
    findChild(pickedTimeWithTrueChildren);

    if (mappedValue.length === 0) {
      return timeTypeMandatory;
    }
    if (
      (child.childMandatory === true && leafNode.childMandatory === true) ||
      child.childMandatory === false
    ) {
      return false;
    }
    return true;
  }, [getTimeTypesTree, mappedValue, timeTypeMandatory, timeTypes]);

  const filteredArticles = useMemo(
    () => [
      notBillableArticle,
      ...(billableArticles?.filter(
        (x) => x.isTravelTime === localValue.travelTime
      ) ?? []),
    ],
    [billableArticles, localValue.travelTime, notBillableArticle]
  );

  const isValidTimeSpent = useMemo(() => {
    if (!localValue.amount) {
      return false;
    }
    if (localValue.amount === "00:00") {
      return false;
    }
    return true;
  }, [localValue.amount]);

  function onChangeTravelTime(travelTime) {
    const newArticles = [
      ...(billableArticles?.filter((x) => x.isTravelTime === travelTime) ?? []),
    ];
    let articleId = localValue?.articleId;
    //if travelTime is changed, it should reset chosen articleId,
    //because articles array will be filtered by travelTime
    if (travelTime !== localValue?.travelTime) {
      if (newArticles.length === 0) {
        articleId = notBillableArticle.articleId;
      } else if (newArticles.length === 1) {
        articleId = newArticles[0].articleId;
      } else {
        articleId = undefined;
      }
    }
    setLocalValue({...localValue, travelTime, articleId});
  }

  function onChangeTypeOfTime(timeType) {
    let timeTypeKey;

    function findKey(array) {
      array.forEach(function (t) {
        timeTypeKey = t.key;
        if (Array.isArray(t.children)) {
          findKey(t.children);
        }
      });
    }

    findKey(timeType);
    setLocalValue({...localValue, timeTypeId: timeTypeKey});
  }

  function onOkButtonClick() {
    const spentTimeRegistration = isEditMode
      ? value.spentTimeRegistration.map((x) =>
          x.id === selectedId ? {...localValue} : x
        )
      : value?.spentTimeRegistration
      ? [...(value?.spentTimeRegistration ?? []), localValue]
      : [localValue];

    onChange({
      ...value,
      spentTimeRegistration,
    });
    onClose();
  }

  return (
    <Modal size="xl" static show={true} onClose={onClose}>
      <ModalHeader>{t("addTime")}</ModalHeader>
      <ModalBody>
        <Container>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="d-flex justify-content-between flex-wrap">
                <Control
                  labelText={t("timeSpent")}
                  invalidText={t("obligatory")}
                >
                  <>
                    <TimePicker
                      value={localValue?.amount}
                      onChange={(amount) => {
                        setLocalValue({...localValue, amount});
                      }}
                      // required // Dont use required, it allows 00:00
                      invalid={
                        !localValue.amount || localValue.amount === "00:00"
                      }
                    />
                    {(!localValue.amount || localValue.amount === "00:00") && (
                      <div style={errorMessageStyle}>{[t("obligatory")]}</div>
                    )}
                  </>
                </Control>
                <Control labelText={t("travelTime")}>
                  <>
                    {/* Custom error printing here since SBWebb does not behave like we want */}
                    <ButtonSwitch
                      value={localValue?.travelTime}
                      onChange={(travelTime) => onChangeTravelTime(travelTime)}
                      style={
                        localValue?.travelTime === undefined
                          ? errorBorderStyle
                          : {}
                      }
                    >
                      <ButtonSwitchOption value={true}>
                        {t("yes")}
                      </ButtonSwitchOption>
                      <ButtonSwitchOption value={false}>
                        {t("no")}
                      </ButtonSwitchOption>
                    </ButtonSwitch>
                    {localValue?.travelTime === undefined && (
                      <div style={errorMessageStyle}>{[t("obligatory")]}</div>
                    )}
                  </>
                </Control>
                <Control labelText={t("planned")}>
                  <>
                    {/* Custom error printing here since SBWebb does not behave like we want */}
                    <ButtonSwitch
                      value={localValue?.plannedTime}
                      onChange={(plannedTime) => {
                        setLocalValue({...localValue, plannedTime});
                      }}
                      style={
                        localValue?.plannedTime === undefined
                          ? errorBorderStyle
                          : {}
                      }
                    >
                      <ButtonSwitchOption value={true}>
                        {t("yes")}
                      </ButtonSwitchOption>
                      <ButtonSwitchOption value={false}>
                        {t("no")}
                      </ButtonSwitchOption>
                    </ButtonSwitch>
                    {localValue?.plannedTime === undefined && (
                      <div style={errorMessageStyle}>{[t("obligatory")]}</div>
                    )}
                  </>
                </Control>
              </div>
              <div>
                <Control
                  labelText={t("typeOfTime")}
                  className="flex-grow-1 mt-2"
                >
                  <XTreeView
                    style={{maxHeight: 310}}
                    label={t("typeOfTime")}
                    options={getTimeTypesTree(timeTypes)}
                    value={mappedValue}
                    onChange={(timeType) => onChangeTypeOfTime(timeType)}
                    searchable={true}
                    multi={false}
                    validationErrors={isTimeTypeMandatory ? ["error"] : []}
                  />
                </Control>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <Control labelText={t("article")}>
                <>
                  <Select
                    className="mb-3"
                    onChange={(articleId) =>
                      setLocalValue({...localValue, articleId})
                    }
                    options={filteredArticles}
                    value={localValue?.articleId}
                    disabled={localValue?.travelTime === undefined}
                    getOptionKey={(x) => x.articleId}
                    getOptionValue={(x) => x.articleId}
                    getOptionText={(x) => x.title}
                    invalid={localValue?.articleId === undefined ? true : false}
                  />

                  {/* SB webb Select component invalid text gets printed strange, write our own */}
                  {localValue?.articleId === undefined && (
                    <div
                      className="invalid-feedback"
                      style={{marginTop: "-12px"}}
                    >
                      {[t("obligatory")]}
                    </div>
                  )}
                </>
              </Control>
              <Control labelText={t("performedBy")}>
                <Select
                  className="mb-3"
                  onChange={(performedByCaseWorkerId) =>
                    setLocalValue({...localValue, performedByCaseWorkerId})
                  }
                  options={caseWorkers.filter(
                    (cw) =>
                      (!cw.deleted && cw.isHandlingOfficer) ||
                      cw.id === localValue?.performedByCaseWorkerId
                  )}
                  value={localValue?.performedByCaseWorkerId}
                  getOptionKey={(x) => x.id}
                  getOptionValue={(x) => x.id}
                  getOptionText={(x) => x.name}
                />
              </Control>
            </div>
          </div>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          onClick={onClose}
          className="mr-2"
          icon={faTimes}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={onOkButtonClick}
          icon={faCheck}
          kind="primary"
          disabled={
            isTimeTypeMandatory ||
            !isValidTimeSpent ||
            localValue?.articleId === undefined ||
            localValue?.plannedTime === undefined ||
            localValue?.travelTime === undefined
          }
        >
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export function TimeRegistrationModalButon({onChange, value, selectedId}) {
  const [isOpen, setIsOpen] = useState(false);
  const t = useAppTranslation();
  return (
    <>
      {selectedId ? (
        <Button
          size="sm"
          kind="danger-hover-ghost"
          icon={faEdit}
          onClick={() => setIsOpen(true)}
          tooltipText={t("edit")}
        />
      ) : (
        <div className="pr-3">
          <Button icon={faPlus} onClick={() => setIsOpen(true)} kind="primary">
            {t("addTime")}
          </Button>
        </div>
      )}
      {isOpen && (
        <TimeRegistrationModal
          onChange={onChange}
          onClose={() => setIsOpen(false)}
          value={value}
          selectedId={selectedId}
        />
      )}
    </>
  );
}
