import {createContext, useContext} from "react";
import {useServiceWorkerRepository} from "./sw-comms/useServiceWorkerRepository";

const CurrentEcosUserIdContext = createContext();

export function CurrentEcosUserIdProvider({children}) {
  const {items} = useServiceWorkerRepository("current-ecos-user");

  const item = items?.["_"];
  const ecosUserId = item?.ecosUserId;

  return (
    <CurrentEcosUserIdContext.Provider value={ecosUserId}>
      {children}
    </CurrentEcosUserIdContext.Provider>
  );
}

export function useCurrentEcosUserId() {
  return useContext(CurrentEcosUserIdContext);
}
