import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrors, Validation} from "@sokigo-sbwebb/validation";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TextBox,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useEffect, useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {useServerState} from "../../../ServerStateProvider";
import CommonDocumentsSchema from "./CommonDocumentsSchema.json";
import getPrettyFileSize from "./getPrettyFileSize";

export function EditCommonDocumentsModal({
  documents: initialDocuments,
  onClose,
  onSave,
  isCreation,
}) {
  const t = useAppTranslation();
  const {addErrorNotification} = useNotifications();
  const {commonDocuments} = useServerState();
  const [documents, setDocuments] = useState(
    initialDocuments.map((x) => ({id: uuid(), ...x}))
  );
  const [saving, setSaving] = useState(false);

  const documentCategoriesOptions = useMemo(
    () =>
      [
        ...new Set(
          Object.values(commonDocuments)
            .map((x) => x.documentCategories)
            .flat(1)
        ),
      ].filter((x) => x),
    [commonDocuments]
  );

  const validation = useMemo(() => new Validation(CommonDocumentsSchema), []);
  const validationErrors = useMemo(
    () => validation.validate(documents, t),
    [validation, documents, t]
  );
  const canSave = validationErrors.length === 0;

  async function save() {
    setSaving(true);
    try {
      await onSave(documents);
      // addSuccessNotification(
      //   t("commonDocumentSaved", {count: documents.length})
      // );
    } catch (err) {
      addErrorNotification(
        t("commonDocumentCouldNotBeSaved", {count: documents.length})
      );
      throw err;
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    // remove all files => nothing left to edit
    if (documents.length === 0) {
      onClose();
    }
  }, [documents, onClose]);

  const columns = [
    {
      key: "documentCategories",
      header: t("documentCategories"),
      renderCell: ({row, onRowChange}) => {
        return (
          <Select
            size="sm"
            creatable
            multiple
            clearable
            onChange={(documentCategories) => {
              onRowChange({...row, documentCategories});
            }}
            getOptionKey={(x) => x}
            getOptionText={(x) => x}
            getNewOptionData={(x) => x}
            options={documentCategoriesOptions}
            value={row.documentCategories}
            searchable={false}
            locale={t("locale")}
          />
        );
      },
    },
    {
      key: "title",
      header: t("title"),
      renderCell: ({row, onRowChange, index}) => {
        const errs = getValidationErrors(validationErrors, index, "title");

        return (
          // TextBox has to be wrapped in position:relative element or the invalid icon flies away
          <div className="position-relative">
            <TextBox
              size="sm"
              value={row.title ?? ""}
              onChange={(title) => onRowChange({...row, title})}
              invalid={errs.length > 0}
            />
          </div>
        );
      },
    },
    {
      key: "fileName",
      header: t("fileName"),
      renderCell: ({row}) => (isCreation ? row.file.name : row.fileName),
    },
    {
      key: "fileSize",
      header: t("fileSize"),
      renderCell: ({row}) =>
        getPrettyFileSize(isCreation ? row.file.size : row.size),
    },
  ];
  return (
    <Modal show onClose={onClose} size="xl" scrollable={false}>
      <fieldset disabled={saving}>
        <ModalHeader onClose={onClose}>
          {isCreation ? t("addCommonDocuments") : t("editCommonDocument")}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col">
              <Table
                rows={documents}
                columns={columns}
                getRowKey={(x) => x.id}
                onChange={(files) => setDocuments(files)}
              >
                <TableHead>
                  <TableHeaderRow colSpan={columns.length + 1}>
                    {(columns) => (
                      <>
                        {columns.map((x) => (
                          <TableHeader key={x.key} column={x}>
                            {x.header}
                          </TableHeader>
                        ))}
                      </>
                    )}
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {(rows) =>
                    rows.map((r) => (
                      <Fragment key={r.key}>
                        <TableRow
                          key={r.key}
                          row={r}
                          invalid={
                            getValidationErrors(validationErrors, r.rowIndex)
                              .length > 0
                          }
                        >
                          {r.cells.map((c) => (
                            <TableCell key={c.key}>{c.value}</TableCell>
                          ))}
                        </TableRow>
                      </Fragment>
                    ))
                  }
                </TableBody>
              </Table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} className="mr-2" icon={faTimes}>
            {t("cancel")}
          </Button>
          <Button
            kind="primary"
            onClick={save}
            icon={faCheck}
            disabled={!canSave}
          >
            {t("ok")}
          </Button>
        </ModalFooter>
      </fieldset>
    </Modal>
  );
}
