import {faEdit, faPlus, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {HelpLink, useConfirmDialog} from "@sokigo-sbwebb/default-components";
import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {useMemo, useState} from "react";
import {useServerState} from "../../../ServerStateProvider";
import {ManageGenericControlAreaGroupsModal} from "./ManageGenericControlAreaGroupsModal";

function ManageGenericControlAreasButton({controlAreaGroup}) {
  const t = useAppTranslation();
  const [show, setShow] = useState(false);
  const {addErrorNotification} = useNotifications();
  const {updateControlAreaGroup} = useServerState();

  async function save(controlAreaGroup) {
    try {
      await updateControlAreaGroup(controlAreaGroup);
      setShow(false);
    } catch (err) {
      addErrorNotification(t("controlAreaGroupCouldNotBeSaved"));
      throw err;
    }
  }

  const isCreation = !controlAreaGroup;

  return (
    <>
      <Button
        size={isCreation ? undefined : "sm"}
        kind={isCreation ? "primary" : "ghost"}
        icon={isCreation ? faPlus : faEdit}
        onClick={() => setShow(true)}
        tooltipText={isCreation ? undefined : t("edit")}
        className="mr-1"
      >
        {isCreation ? t("createControlAreaGroup") : null}
      </Button>
      {show && (
        <ManageGenericControlAreaGroupsModal
          show={show}
          onClose={() => setShow(false)}
          onSave={save}
          controlAreaGroup={controlAreaGroup}
          headerTitle={
            isCreation ? t("createControlAreaGroup") : t("editControlAreaGroup")
          }
        />
      )}
    </>
  );
}

export function GenericControlAreas() {
  const t = useAppTranslation();
  const {controlAreaGroups, removeControlAreaGroup, refreshVisitForms} =
    useServerState();
  const [ConfirmModal, confirmDelete] = useConfirmDialog();
  const {addErrorNotification} = useNotifications();

  async function remove(controlAreaGroupId) {
    const c = await confirmDelete();
    if (c) {
      try {
        await removeControlAreaGroup(controlAreaGroupId);
        await refreshVisitForms();
        // addSuccessNotification(t("controlAreaGroupSuccessfullyRemoved"));
      } catch (err) {
        addErrorNotification(t("controlAreaGroupCouldNotBeRemoved"));
        throw err;
      }
    }
  }

  const sortedControlAreaGroups = useMemo(
    () =>
      Object.keys(controlAreaGroups)
        .map((key) => controlAreaGroups[key])
        .sort((a, b) => a.title.localeCompare(b.title, t("locale"))),
    [controlAreaGroups, t]
  );

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center mb-2">
        <h4>{t("controlAreaGroups")}</h4>
        <div className="d-flex flex-row mr-3">
          <ManageGenericControlAreasButton controlAreaGroup={null} />
          <HelpLink section="controlAreasGenericInspection" />
        </div>
      </div>
      {controlAreaGroups && (
        <Table
          rows={sortedControlAreaGroups}
          columns={[
            {
              key: "1",
              field: "title",
            },
          ]}
          getRowKey={(x) => x.id}
        >
          <TableBody>
            {(rows) =>
              rows.map((r) => (
                <TableRow key={r.key} row={r}>
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell>
                    <ManageGenericControlAreasButton
                      foodInspectionArea={r.row}
                      controlAreaGroup={r.row}
                    />
                    <Button
                      size="sm"
                      kind="danger-hover-ghost"
                      icon={faTrashAlt}
                      onClick={() => remove(r.row.id)}
                      tooltipText={t("remove")}
                    />
                  </TableRowActionsCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      )}
      <ConfirmModal />
    </>
  );
}
