import {debounce} from "lodash-es";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {DeviceVisitCheckinStatus} from "./DeviceVisitCheckinStatus";
import {useCurrentEcosUserId} from "../../useCurrentEcosUserId";
import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useHistory} from "react-router-dom";
import {deleteJson, postJson} from "@sokigo-sbwebb/fetch";
import {useServerState} from "../../ServerStateProvider";
import {Busy} from "./Busy";
import {useConfirmDialog} from "@sokigo-sbwebb/default-components";
import {SaveToEcosStatus} from "../../components/Common/SaveToEcosStatus";

const AddDeviceVisitContext = createContext();
const DeviceVisitsContext = createContext();
const UpdateDeviceVisitContext = createContext();
const DeleteDeviceVisitContext = createContext();
const SyncDeviceVisitImmediatelyContext = createContext();
const SaveVisitToServerContext = createContext();
const FinishVisitContext = createContext();
const CheckInVisitContext = createContext();
const CheckOutVisitContext = createContext();
const HijackVisitContext = createContext();
const DeleteServerVisitContext = createContext();
const ClearSuccessfullyFinishedVisitsContext = createContext();
const RetryAbortedFinishedVisitContext = createContext();
const DeleteFinishedVisitContext = createContext();
const StartVisitContext = createContext();
const ReopenVisitWithValidationErrorContext = createContext();

export const BusyState = {
  None: 0,
  CheckingIn: 1,
  CheckingInFailed: 2,
  Finishing: 3,
  FinishFailed: 4,
  Deleting: 5,
  DeleteFailed: 6,
  CheckingOut: 7,
  CheckingOutFailed: 8,
  RetryingAborted: 9,
  RetryAbortedFailed: 10,
  ClearingSuccessfullyFinished: 11,
  ClearSuccessfullyFinishedFailed: 12,
  DeleteFinishedVisitFailed: 13,
  ReopeningVisitWithValidationError: 14,
  ReopeningVisitWithValidationErrorFailed: 15,
};

export function DeviceVisitsProvider({children}) {
  const t = useAppTranslation();
  const debounceTime = 1000; // no  need to hammer the storage provider while writing
  const [deviceVisits, setDeviceVisits] = useState(null);
  const currentEcosUserId = useCurrentEcosUserId();
  const {refreshServerVisits} = useServerState();
  const {push} = useHistory();
  const {addErrorNotification} = useNotifications();

  const [navigateOnNextRender, setNavigateOnNextRender] = useState(null);
  useEffect(() => {
    if (!navigateOnNextRender) {
      return;
    }
    if (
      navigateOnNextRender.waitUntilVisitIsDeleted &&
      deviceVisits[navigateOnNextRender.waitUntilVisitIsDeleted]
    ) {
      // the deletion of the visit has not propagated to the tab yet
      return;
    }
    if (
      navigateOnNextRender.waitUntilVisitExists &&
      !deviceVisits[navigateOnNextRender.waitUntilVisitExists]
    ) {
      // the creation of the visit has not propagated to the tab yet
      return;
    }
    setNavigateOnNextRender(null);
    push(navigateOnNextRender.target);
  }, [navigateOnNextRender, deviceVisits, push]);

  const updateItem = useCallback(
    async function (visitWithStatus, newAttachments = null) {
      await postWithResponse({
        type: "INSPECTION_VISIT_UPDATE",
        visitWithStatus,
        newAttachments,
        currentEcosUserId,
      });
    },
    [currentEcosUserId]
  );

  useEffect(() => {
    let cleanup = null;
    (async function () {
      const {active} = await navigator.serviceWorker.ready;

      function handle(messageEvent) {
        if (!messageEvent.data.type?.startsWith?.("INSPECTION_VISIT_")) {
          return;
        }

        switch (messageEvent.data.type) {
          case "INSPECTION_VISIT_ADDED":
            if (
              messageEvent.data.visitWithStatus.visit.ownerId ===
              currentEcosUserId
            ) {
              setDeviceVisits((visits) => ({
                ...visits,
                [messageEvent.data.visitWithStatus.visit.id]:
                  messageEvent.data.visitWithStatus,
              }));
            }
            break;
          case "INSPECTION_VISIT_UPDATED":
            if (
              messageEvent.data.visitWithStatus.visit.ownerId ===
              currentEcosUserId
            ) {
              setDeviceVisits((visits) => {
                if (
                  visits[messageEvent.data.visitWithStatus.visit.id] &&
                  visits[messageEvent.data.visitWithStatus.visit.id]
                    .changeDate > messageEvent.data.visitWithStatus.changeDate
                ) {
                  // local copy has been changed after the one from the event - keep the local one
                  return visits;
                }
                // only update the input and status properties
                // the rest of the values are static
                // this prevents needlessly re-rendering static content which may be large
                return {
                  ...visits,
                  [messageEvent.data.visitWithStatus.visit.id]: {
                    ...messageEvent.data.visitWithStatus,
                    visit: {
                      input: messageEvent.data.visitWithStatus.visit.input,
                      ...(
                        visits[messageEvent.data.visitWithStatus.visit.id] ?? {}
                      ).visit,
                    },
                  },
                };
              });
            }
            break;
          case "INSPECTION_VISIT_DELETED":
            if (messageEvent.data.ownerId === currentEcosUserId) {
              setDeviceVisits((visits) => {
                const {
                  // eslint-disable-next-line no-unused-vars
                  [messageEvent.data.visitId]: _deletedVisit,
                  ...otherVisits
                } = visits;
                return otherVisits;
              });
            }
        }
      }

      navigator.serviceWorker.addEventListener("message", handle);
      cleanup = () => active.removeEventListener("message", handle);
    })();
    return () => cleanup?.();
  }, [currentEcosUserId]);

  useEffect(() => {
    // fetch existing items on mount
    (async function () {
      const response = await postWithResponse({
        type: "INSPECTION_VISIT_GET",
        currentEcosUserId,
      });

      const currentUserVisitsById = Object.keys(response).reduce(
        (res, key) => ((res[key] = response[key]), res),
        {}
      );
      setDeviceVisits(currentUserVisitsById);
    })();
  }, [currentEcosUserId]);

  const throttledUpdate = useMemo(
    () =>
      debounce((visitWithStatus) => {
        updateItem(visitWithStatus);
      }, debounceTime),
    [updateItem]
  );

  const updateDeviceVisit = useCallback(
    (visitWithStatus, newAttachments) => {
      const previousVisit = deviceVisits[visitWithStatus.visit.id];
      if (previousVisit.checkinStatus !== DeviceVisitCheckinStatus.CheckedOut) {
        throw new Error("Visit is not in a state where updates are allowed");
      }

      const visitWithStatusAndTime = {
        ...visitWithStatus,
        changeDate: new Date().getTime(),
      };
      setDeviceVisits({
        ...deviceVisits,
        [visitWithStatusAndTime.visit.id]: visitWithStatusAndTime,
      });
      if (newAttachments) {
        updateItem(visitWithStatusAndTime, newAttachments);
      } else {
        throttledUpdate(visitWithStatusAndTime);
      }
    },
    [deviceVisits, throttledUpdate, updateItem]
  );

  const syncDeviceVisitImmediately = useCallback(
    async (visitId) => {
      const visitWithStatus = deviceVisits[visitId];
      await updateItem(visitWithStatus);
    },
    [deviceVisits, updateItem]
  );

  const [busyState, setBusyState] = useState(BusyState.None);
  const [ConfirmDeleteModal, confirmDelete] = useConfirmDialog();
  const [ConfirmTryOccurrenceAgainModal, confirmTryOccurrenceAgain] =
    useConfirmDialog();
  const [ConfirmTryProtocolAgainModal, confirmTryProtocolAgain] =
    useConfirmDialog();

  if (!deviceVisits) {
    return null;
  }

  async function postWithResponse(message) {
    const {active} = await navigator.serviceWorker.ready;
    const response = await new Promise((resolve) => {
      const mc = new MessageChannel();
      mc.port1.onmessage = function (evt) {
        mc.port1.close();
        mc.port2.close();
        resolve(evt.data);
      };
      active.postMessage(message, [mc.port2]);
    });
    await new Promise((resolve) => {
      // allow other messages sent by sw during the operation to be handled before proceeding
      setTimeout(() => resolve(), 0);
    });
    return response;
  }

  async function addDeviceVisit(visitResponse) {
    await postWithResponse({
      type: "INSPECTION_VISIT_ADD",
      visitResponse,
    });
  }

  async function saveVisitToServer(visitId) {
    await postWithResponse({
      type: "INSPECTION_VISIT_SAVE_IMMEDIATELY",
      visitId,
      currentEcosUserId,
    });
  }

  async function deleteDeviceVisit(visitId) {
    await postWithResponse({
      type: "INSPECTION_VISIT_DELETE",
      visitId,
      currentEcosUserId,
    });
  }

  async function finishVisit(visitId) {
    setBusyState(BusyState.Finishing);
    throttledUpdate.cancel();
    await syncDeviceVisitImmediately(visitId);
    const response = await postWithResponse({
      type: "INSPECTION_VISIT_FINISH",
      visitId,
      currentEcosUserId,
    });
    if (response === "INSPECTION_VISIT_FINISH_SUCCESS") {
      setNavigateOnNextRender({
        target: "/inspection",
        waitUntilVisitIsDeleted: visitId,
      });
      // addSuccessNotification(t("visitSaved"));
      setBusyState(BusyState.None);
    } else {
      setBusyState(BusyState.FinishFailed);
    }
  }

  async function checkInVisit(visitId) {
    setBusyState(BusyState.CheckingIn);
    throttledUpdate.cancel();
    await syncDeviceVisitImmediately(visitId);
    const response = await postWithResponse({
      type: "INSPECTION_VISIT_CHECK_IN",
      visitId,
      currentEcosUserId,
    });
    if (response === "INSPECTION_VISIT_CHECK_IN_SUCCESS") {
      setNavigateOnNextRender({
        target: "/inspection",
        waitUntilVisitIsDeleted: visitId,
      });
      // addSuccessNotification(t("visitSaved"));
      setBusyState(BusyState.None);
    } else {
      setBusyState(BusyState.CheckingInFailed);
    }
  }

  async function checkOutVisit(visitId, navigate) {
    setBusyState(BusyState.CheckingOut);
    const response = await postWithResponse({
      type: "INSPECTION_VISIT_CHECK_OUT",
      visitId,
      currentEcosUserId,
    });
    if (response === "INSPECTION_VISIT_CHECK_OUT_SUCCESS") {
      if (navigate) {
        setNavigateOnNextRender({
          target: "/inspection/visit/" + visitId,
          waitUntilVisitExists: visitId,
        });
      }
      setBusyState(BusyState.None);
    } else {
      setBusyState(BusyState.CheckingOutFailed);
    }
  }

  async function hijackVisit(visitId) {
    setBusyState(BusyState.CheckingOut);
    const response = await postWithResponse({
      type: "INSPECTION_VISIT_HIJACK",
      visitId,
      currentEcosUserId,
    });
    if (response === "INSPECTION_VISIT_HIJACK_SUCCESS") {
      setNavigateOnNextRender({
        target: "/inspection/visit/" + visitId,
        waitUntilVisitExists: visitId,
      });
      setBusyState(BusyState.None);
    } else {
      setBusyState(BusyState.CheckingOutFailed);
    }
  }

  async function deleteServerVisit(visitId) {
    if (!(await confirmDelete())) {
      return;
    }
    setBusyState(BusyState.Deleting);
    try {
      const checkoutId = deviceVisits[visitId]?.visit.checkoutId;
      const url = checkoutId
        ? "api/inspection/visit/" + visitId + "/" + checkoutId
        : "api/inspection/visit/" + visitId;
      await deleteJson(url);
      await refreshServerVisits();
      await deleteDeviceVisit(visitId);
      setTimeout(() => {
        // allow other messages sent by sw during the operation to be handled before proceeding
        setBusyState(BusyState.None);
      }, 0);
    } catch (ex) {
      setBusyState(BusyState.DeleteFailed);
    }
  }

  async function clearSuccessfullyFinishedVisits() {
    setBusyState(BusyState.ClearingSuccessfullyFinished);
    try {
      await deleteJson("api/inspection/visit/clearSuccessfullyFinishedVisits");
      refreshServerVisits();
      setBusyState(BusyState.None);
    } catch {
      try {
        await refreshServerVisits();
      } catch {
        //ignore
      }
      setBusyState(BusyState.ClearSuccessfullyFinishedFailed);
    }
  }

  async function retryAbortedFinishedVisit(visitId, currentSaveToEcosStatus) {
    const confirm = {
      [SaveToEcosStatus.CreateOccurrenceAborted]: confirmTryOccurrenceAgain,
      [SaveToEcosStatus.SaveProtocolAborted]: confirmTryProtocolAgain,
    }[currentSaveToEcosStatus];
    if (!confirm) {
      setBusyState(BusyState.RetryAbortedFailed);
      return;
    }
    if (!(await confirm())) {
      return;
    }

    setBusyState(BusyState.RetryingAborted);
    try {
      await postJson(
        `api/inspection/visit/retryAborted/${visitId}`,
        currentSaveToEcosStatus
      );
      await refreshServerVisits();
      setBusyState(BusyState.None);
    } catch (err) {
      try {
        await refreshServerVisits();
      } catch {
        //ignore
      }
      setBusyState(BusyState.RetryAbortedFailed);
    }
  }

  async function reopenVisitWithValidationError(visit) {
    setBusyState(BusyState.ReopeningVisitWithValidationError);
    const response = await postWithResponse({
      type: "INSPECTION_VISIT_REOPEN_VALIDATION_ERROR",
      visitId: visit.id,
      currentEcosUserId,
    });
    if (response === "INSPECTION_VISIT_REOPEN_VALIDATION_SUCCESS") {
      const finishType = visit.inspectionType === 3 ? "finish24" : "finish";

      setNavigateOnNextRender({
        target: "/inspection/visit/" + visit.id + "/" + finishType + "",
        waitUntilVisitExists: visit.id,
      });
      setBusyState(BusyState.None);
    } else {
      setBusyState(BusyState.ReopeningVisitWithValidationErrorFailed);
    }
  }

  async function deleteFinishedVisit(visitId) {
    if (!(await confirmDelete())) {
      return;
    }
    setBusyState(BusyState.Deleting);
    try {
      await deleteJson(
        `api/inspection/visit/removeUnsuccessfullyFinishedVisit/${visitId}`
      );
      refreshServerVisits();
      setBusyState(BusyState.None);
    } catch {
      setBusyState(BusyState.DeleteFinishedVisitFailed);
    }
  }

  async function startVisit({visitTargetId, visitFormId, newCaseParameters}) {
    const response = await postWithResponse({
      type: "INSPECTION_VISIT_START",
      visitTargetId,
      visitFormId,
      newCaseParameters,
      currentEcosUserId,
    });
    if (response.type === "INSPECTION_VISIT_CREATE_SUCCESS") {
      const {visitId} = response;
      setNavigateOnNextRender({
        target: "/inspection/visit/" + visitId,
        waitUntilVisitExists: visitId,
      });
    } else {
      addErrorNotification(t("couldNotStartVisit"));
    }
  }

  return (
    <AddDeviceVisitContext.Provider value={addDeviceVisit}>
      <DeviceVisitsContext.Provider value={deviceVisits}>
        <UpdateDeviceVisitContext.Provider value={updateDeviceVisit}>
          <DeleteDeviceVisitContext.Provider value={deleteDeviceVisit}>
            <SyncDeviceVisitImmediatelyContext.Provider
              value={syncDeviceVisitImmediately}
            >
              <SaveVisitToServerContext.Provider value={saveVisitToServer}>
                <FinishVisitContext.Provider value={finishVisit}>
                  <CheckInVisitContext.Provider value={checkInVisit}>
                    <CheckOutVisitContext.Provider value={checkOutVisit}>
                      <HijackVisitContext.Provider value={hijackVisit}>
                        <DeleteServerVisitContext.Provider
                          value={deleteServerVisit}
                        >
                          <ClearSuccessfullyFinishedVisitsContext.Provider
                            value={clearSuccessfullyFinishedVisits}
                          >
                            <RetryAbortedFinishedVisitContext.Provider
                              value={retryAbortedFinishedVisit}
                            >
                              <ReopenVisitWithValidationErrorContext.Provider
                                value={reopenVisitWithValidationError}
                              >
                                <DeleteFinishedVisitContext.Provider
                                  value={deleteFinishedVisit}
                                >
                                  <StartVisitContext.Provider
                                    value={startVisit}
                                  >
                                    {children}
                                    <Busy
                                      busyState={busyState}
                                      onDismiss={() =>
                                        setBusyState(BusyState.None)
                                      }
                                    />
                                    <ConfirmDeleteModal>
                                      {t("deleteVisitConfirmationMessage")}
                                    </ConfirmDeleteModal>
                                    <ConfirmTryOccurrenceAgainModal
                                      title={`${t("tryAgain")}?`}
                                    >
                                      {t(
                                        "confirmationMessageForCreateOccurrenceAbortedStatus"
                                      )}
                                    </ConfirmTryOccurrenceAgainModal>
                                    <ConfirmTryProtocolAgainModal
                                      title={`${t("tryAgain")}?`}
                                    >
                                      {t(
                                        "confirmationMessageForSaveProtocolAbortedStatus"
                                      )}
                                    </ConfirmTryProtocolAgainModal>
                                  </StartVisitContext.Provider>
                                </DeleteFinishedVisitContext.Provider>
                              </ReopenVisitWithValidationErrorContext.Provider>
                            </RetryAbortedFinishedVisitContext.Provider>
                          </ClearSuccessfullyFinishedVisitsContext.Provider>
                        </DeleteServerVisitContext.Provider>
                      </HijackVisitContext.Provider>
                    </CheckOutVisitContext.Provider>
                  </CheckInVisitContext.Provider>
                </FinishVisitContext.Provider>
              </SaveVisitToServerContext.Provider>
            </SyncDeviceVisitImmediatelyContext.Provider>
          </DeleteDeviceVisitContext.Provider>
        </UpdateDeviceVisitContext.Provider>
      </DeviceVisitsContext.Provider>
    </AddDeviceVisitContext.Provider>
  );
}

export function useSaveVisitToServerImmediately() {
  return useContext(SaveVisitToServerContext);
}

export function useAddDeviceVisit() {
  return useContext(AddDeviceVisitContext);
}

export function useDeviceVisits() {
  return useContext(DeviceVisitsContext);
}

export function useUpdateDeviceVisit() {
  return useContext(UpdateDeviceVisitContext);
}

export function useDeleteDeviceVisit() {
  return useContext(DeleteDeviceVisitContext);
}

export function useSyncDeviceVisitToServiceWorkerImmediately() {
  return useContext(SyncDeviceVisitImmediatelyContext);
}

export function useFinishVisit() {
  return useContext(FinishVisitContext);
}

export function useCheckInVisit() {
  return useContext(CheckInVisitContext);
}

export function useCheckOutVisit() {
  return useContext(CheckOutVisitContext);
}
export function useHijackVisit() {
  return useContext(HijackVisitContext);
}

export function useDeleteServerVisit() {
  return useContext(DeleteServerVisitContext);
}

export function useClearSuccessfullyFinishedVisits() {
  return useContext(ClearSuccessfullyFinishedVisitsContext);
}

export function useRetryAbortedFinishedVisit() {
  return useContext(RetryAbortedFinishedVisitContext);
}

export function useReopenVisitWithValidationError() {
  return useContext(ReopenVisitWithValidationErrorContext);
}

export function useDeleteFinishedVisit() {
  return useContext(DeleteFinishedVisitContext);
}

export function useStartVisit() {
  return useContext(StartVisitContext);
}
