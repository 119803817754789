import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  getValidationErrors,
  getValidationErrorMessages,
} from "@sokigo-sbwebb/validation";
import {
  Control,
  TextBox,
  ButtonSwitch,
  ButtonSwitchOption,
  Select,
} from "@sokigo/components-react-bootstrap";
import {useServerState} from "../../../../../ServerStateProvider";
import {SaveAsType} from "./SaveAsType";
import {ControlResponseFormat} from "./ControlResponseFormat";
import PredefinedEmail from "./PredefinedEmail";
import {faCheck, faMinus, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useWindowDimensions from "../../../../../../../common-components/useWindowDimensions";

export const General = ({visitForm, validationErrors, onChange}) => {
  const {
    baseData: {facilityTypes, availableTemplates},
  } = useServerState();
  const t = useAppTranslation();

  //TODO should find a better solution to this, probably need some boolean added in the basedata for Livsmedelsanläggning
  const foodInspectionFacilityTypeIds =
    visitForm.saveAsType === SaveAsType.FoodInspection ||
    visitForm.saveAsType === SaveAsType.FoodInspection24
      ? [facilityTypes.find((x) => x.title === "Livsmedelsanläggning").id]
      : null;

  const {windowWidth} = useWindowDimensions();

  return (
    <div>
      <div className="row">
        <Control
          className="col-md-6"
          labelText={t("title")}
          errors={getValidationErrorMessages(validationErrors, "title")}
        >
          <TextBox
            value={visitForm.title}
            required
            name="title"
            onChange={(title) => onChange({...visitForm, title})}
          />
        </Control>
        <Control labelText={t("subTitle")} className="col-md-6">
          <TextBox
            value={visitForm.subTitle || ""}
            name="subTitle"
            onChange={(subTitle) => {
              onChange({...visitForm, subTitle});
            }}
          />
        </Control>
      </div>
      <Control labelText={t("type")}>
        <ButtonSwitch
          value={visitForm.saveAsType}
          onChange={(saveAsType) => {
            const sections =
              saveAsType === SaveAsType.FoodInspection ||
              saveAsType === SaveAsType.FoodInspection24
                ? // controlAreas mandatory for food inspections
                  {...visitForm.sections, controlAreas: true}
                : visitForm.sections;
            onChange({...visitForm, saveAsType, sections});
          }}
          vertical={windowWidth < 992 ? true : false}
        >
          <ButtonSwitchOption value={SaveAsType.VisitOccurrence}>
            {t("visitOccurrence")}
          </ButtonSwitchOption>
          <ButtonSwitchOption value={SaveAsType.GenericInspection}>
            {t("genericInspection")}
          </ButtonSwitchOption>
          <ButtonSwitchOption value={SaveAsType.FoodInspection24}>
            {t("foodInspection24")}
          </ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
      <Control labelText={t("facilityTypes")}>
        <Select
          withCheckboxes
          disabled={!!foodInspectionFacilityTypeIds}
          multiple
          options={facilityTypes}
          value={foodInspectionFacilityTypeIds ?? visitForm.facilityTypeIds}
          getOptionValue={(x) => x.id}
          getOptionText={(x) => x.title}
          getOptionKey={(x) => x.id}
          onChange={(facilityTypeIds) =>
            onChange({...visitForm, facilityTypeIds})
          }
        />
      </Control>
      <Control
        labelText={t("reportTemplates")}
        errors={getValidationErrorMessages(
          validationErrors,
          "reportTemplateIds"
        )}
      >
        <Select
          multiple
          withCheckboxes
          options={availableTemplates}
          value={visitForm.reportTemplateIds}
          getOptionValue={(x) => x.id}
          getOptionText={(x) => x.title}
          getOptionKey={(x) => x.id}
          onChange={(reportTemplateIds) =>
            onChange({...visitForm, reportTemplateIds})
          }
        />
      </Control>
      {visitForm.saveAsType !== SaveAsType.FoodInspection24 && (
        <Control labelText={t("controlResponseFormat")}>
          <ButtonSwitch
            value={visitForm.controlResponseFormat}
            onChange={(controlResponseFormat) =>
              onChange({...visitForm, controlResponseFormat})
            }
          >
            <ButtonSwitchOption value={ControlResponseFormat.Text}>
              {`${t("noRemark")}/${t("remark")}/${t("notAssessed")}`}
            </ButtonSwitchOption>
            <ButtonSwitchOption value={ControlResponseFormat.Icons}>
              <FontAwesomeIcon
                icon={faCheck}
                className="text-success-alt mr-1"
              />
              <FontAwesomeIcon icon={faTimes} className="text-danger mr-1" />
              <FontAwesomeIcon icon={faMinus} className="text-muted mr-1" />
            </ButtonSwitchOption>
          </ButtonSwitch>
        </Control>
      )}
      <PredefinedEmail
        emailDispatch={visitForm.emailDispatch}
        onChange={(emailDispatch) => {
          onChange({...visitForm, emailDispatch});
        }}
        validationErrors={getValidationErrors(
          validationErrors,
          "emailDispatch"
        )}
      />
    </div>
  );
};
