import {
  faExternalLinkAlt,
  faFileDownload,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import moment from "moment";
import {Fragment} from "react";
import {Link} from "react-router-dom";
import {useServerState} from "../../../../ServerStateProvider";
import {useOfflineDocumentsCacheMetadata} from "../../../../sw-comms/offline-documents-cache/useOfflineDocumentsCacheMetadata";
import {OfflineDocumentStatus} from "../../../Common/OfflineDocumentStatus";
import {OfflineDocumentStatusIndicator} from "../../../Common/OfflineDocumentStatusIndicator";
import {useCurrentOfflineDocumentIds} from "./useCurrentOfflineDocuments";

export function DocumentsTable({documents, size}) {
  const t = useAppTranslation();
  const {
    baseData: {documentTypes},
  } = useServerState();
  const offlineDocumentsMetadata = useOfflineDocumentsCacheMetadata();
  const {offlineDocumentIds, onChangeOfflineDocumentIds} =
    useCurrentOfflineDocumentIds();

  async function onToggle(id) {
    const isOffline = offlineDocumentIds?.find((x) => x === id);
    onChangeOfflineDocumentIds(
      isOffline
        ? offlineDocumentIds.filter((x) => x !== id)
        : [...(offlineDocumentIds || []), id]
    );
  }

  const columns = [
    {
      key: "date",
      renderCell: (r) =>
        r.row.date ? moment(r.row.date).format("YYYY-MM-DD") : null,
      header: t("date"),
    },
    {
      key: "title",
      field: "title",
      header: t("title"),
    },
    {
      key: "type",
      renderCell: (r) =>
        documentTypes.find((x) => x.id === r.row.documentTypeId)?.name,
      header: t("type"),
    },
    {
      key: "showOffline",
      renderCell: ({row}) => {
        const isOffline = offlineDocumentIds?.find((x) => x === row.id);

        return (
          <Switch
            checked={!!isOffline}
            onChange={() => onToggle(row.id)}
            size={size}
            kind="success"
            disabled={row.notAvailable}
          />
        );
      },
      header: t("showOffline"),
    },
    {
      key: "offlineStatus",
      renderCell: ({row}) => {
        const key = Object.keys(offlineDocumentsMetadata || {}).find(
          (x) => x === row.id
        );
        if (row.notAvailable) {
          return <>{t("notAvailable")}</>;
        }
        if (key) {
          const metadata = offlineDocumentsMetadata[key];
          return <OfflineDocumentStatusIndicator status={metadata.status} />;
        }
      },
      header: t("offlineStatus"),
    },
    {
      key: "show",
      renderCell: ({row}) => {
        if (
          offlineDocumentsMetadata?.[row.id]?.status !==
          OfflineDocumentStatus.Available
        ) {
          return null;
        }
        return (
          <div className="text-right">
            {offlineDocumentsMetadata[row.id].contentType ===
            "application/pdf" ? (
              <Button
                tag={Link}
                to={"/inspection/document/" + row.id}
                target="_blank"
                rel="noopener noreferrer"
                icon={faExternalLinkAlt}
                tooltipText={t("show")}
                kind="ghost"
                size="sm"
              />
            ) : (
              <Button
                tag="a"
                target="_blank"
                rel="noopener noreferrer"
                href={document.baseURI + "cache/caseDocument/" + row.id}
                icon={faExternalLinkAlt}
                tooltipText={t("show")}
                kind="ghost"
                size="sm"
              />
            )}
            <Button
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              href={document.baseURI + "cache/caseDocument/" + row.id}
              icon={faFileDownload}
              tooltipText={t("download")}
              kind="ghost"
              size="sm"
              download={row.filename}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table
      rows={documents}
      columns={columns}
      getRowKey={(x) => x.id}
      size={size}
    >
      <TableHead>
        <TableHeaderRow>
          {(columns) => (
            <>
              {columns.map((x) => (
                <TableHeader key={x.key} column={x}>
                  {x.header}
                </TableHeader>
              ))}
            </>
          )}
        </TableHeaderRow>
      </TableHead>
      <TableBody>
        {(rows) =>
          rows.map((r) => (
            <Fragment key={r.key}>
              <TableRow row={r}>
                {r.cells.map((c) => (
                  <TableCell key={c.key}>{c.value}</TableCell>
                ))}
              </TableRow>
            </Fragment>
          ))
        }
      </TableBody>
    </Table>
  );
}
