import {
  faBook,
  faCheck,
  faPencilAlt,
  faSave,
} from "@fortawesome/pro-regular-svg-icons";
// import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {mergeRefs} from "@sokigo/react-utils";
import {Button} from "@sokigo/components-react-bootstrap";
import {forwardRef, useLayoutEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import {useHistory} from "react-router-dom";
import {useCheckInVisit} from "../../../sw-comms/device-inspections-repo/useDeviceVisits";
import {useCurrentVisit} from "../InspectionVisitRoute";
import {AddAttachmentsButton} from "./attachments/AddAttachmentsButton";
import {FooterPanel, FooterPanelButton, FooterPanelChoice} from "./FooterPanel";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";

function CheckInButton() {
  const t = useAppTranslation();
  const {
    visit: {id},
  } = useCurrentVisit();
  const checkInVisit = useCheckInVisit();
  const isOnline = useIsOnline();

  async function saveAndCheckIn() {
    checkInVisit(id);
  }

  return (
    <Button
      kind="ghost"
      icon={faCheck}
      className="mr-1"
      onClick={saveAndCheckIn}
      disabled={!isOnline}
    >
      {t("saveAndCheckIn")}
    </Button>
  );
}

function SaveAndContinueLaterButton() {
  // const {addSuccessNotification} = useNotifications();
  const t = useAppTranslation();
  const {push} = useHistory();

  async function saveAndContinueLater() {
    push("/inspection");
    // addSuccessNotification(t("visitSaved"));
  }

  return (
    <Button
      kind="ghost"
      icon={faSave}
      className="mr-1"
      onClick={saveAndContinueLater}
    >
      {t("saveAndContinueLater")}
    </Button>
  );
}

export const Footer = forwardRef(function Footer(_, ref) {
  const t = useAppTranslation();
  const [currentPanel, setCurrentPanel] = useState(FooterPanelChoice.None);
  const footerElementRef = useRef();
  const [panelOffset, setPanelOffset] = useState(null);
  useLayoutEffect(() => {
    const h = footerElementRef.current?.offsetHeight;
    setPanelOffset(h);
  }, [setPanelOffset]);

  return (
    <div
      ref={mergeRefs(ref, footerElementRef)}
      className="flex-shrink-0 py-2 px-3 shadow-lg d-flex flex-row justify-content-between position-relative bg-normal"
    >
      {ReactDOM.createPortal(
        <div
          style={{
            position: "absolute",
            right: 0,
            top: "3.5rem",
            bottom: panelOffset,
            display: "flex",
            alignItems: "flex-end",
            padding: "0.75rem 0 0.75rem 0.5rem",
            overflow: "hidden",
          }}
        >
          <FooterPanel
            panel={currentPanel}
            onClose={() => setCurrentPanel(FooterPanelChoice.None)}
          />
        </div>,
        document.body
      )}
      <div className="flex-grow-0">
        <SaveAndContinueLaterButton />
        <CheckInButton />
      </div>
      <div className="flex-grow-0">
        <AddAttachmentsButton />
        <FooterPanelButton
          selectedPanel={currentPanel}
          title={t("commonDocuments")}
          value={FooterPanelChoice.CommonDocuments}
          onSelect={(x) => setCurrentPanel(x)}
          icon={faBook}
        />
        <FooterPanelButton
          selectedPanel={currentPanel}
          title={t("scratchPad")}
          value={FooterPanelChoice.ScratchPad}
          onSelect={(x) => setCurrentPanel(x)}
          icon={faPencilAlt}
        />
      </div>
    </div>
  );
});
