import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Tab, Tabs} from "@sokigo/components-react-bootstrap";
import {useMemo, useState} from "react";
import {
  CompletingInformationProvider,
  CompletingInformationTab,
} from "./CompletingInformation/CompletingInformationTab";
import {FinishVisitTab} from "./FinishVisit/FinishVisitTab";
import CompletingInformationSchema from "./CompletingInformation/CompletingInformationSchema.json";
import FinishVisitSchema from "./FinishVisit/FinishVisitSchema.json";
import DocumentTemplateSchema from "./FinishVisit/DocumentTemplateSchema.json";
import {getValidationErrors, Validation} from "@sokigo-sbwebb/validation";
import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";
import {InputWithTagsProvider} from "./FinishVisit/InputWithTags/InputWithTags";
import {isValidEmailAddress} from "../presentParties/isValidEmailAddress";
import {useAttachmentSizeValidation} from "../attachments/useAttachmentTabValidation";
import {useFoodInspection2013DateValidation} from "../foodInspection24Validator/useFoodInspection24Validation";

function FinishTabInternal({pendingAdditionalEmailAddressIsInvalid}) {
  const t = useAppTranslation();
  const [selectedTab, setSelectedTab] = useState("completingInformation");
  const visitValidation = useMemo(
    () => new Validation(CompletingInformationSchema),
    []
  );
  const visitWithStatus = useCurrentVisit();
  const visitValidationErrors = visitValidation.validate(
    visitWithStatus.visit,
    t
  );
  const {
    visit: {
      input: {finishVisitInfo, completingInformation},
    },
  } = visitWithStatus;

  const inputErrors = getValidationErrors(visitValidationErrors, "input");
  const completingInformationErrors = getValidationErrors(
    inputErrors,
    "completingInformation"
  );
  const dateErrors = useFoodInspection2013DateValidation();

  const isAttachmentTotalSizeToLarge = useAttachmentSizeValidation();

  const {
    visit: {
      baseDataSnapshot: {emailAccounts = []},
    },
  } = useCurrentVisit();

  if (
    emailAccounts.length === 0 ||
    emailAccounts === undefined ||
    emailAccounts === null ||
    !emailAccounts.some(
      (x) => x.id === finishVisitInfo.emailInfo.sendFromEmailAccountId
    )
  ) {
    finishVisitInfo.emailInfo.sendFromEmailAccountId = null;
  }

  const noEmailAddressesSpecified =
    finishVisitInfo.sendByEmail &&
    finishVisitInfo.emailInfo.sendToAdditionalEmailAddresses.length === 0 &&
    finishVisitInfo.emailInfo.sendToPresentPartyIds.length === 0 &&
    finishVisitInfo.emailInfo.sendToCasePartyContactDetails.length === 0;

  const finishVisitInfoValidation = useMemo(
    () => new Validation(FinishVisitSchema),
    []
  );

  const finishVisitInfoValidationErrors = useMemo(
    () => finishVisitInfoValidation.validate(finishVisitInfo, t),
    [finishVisitInfoValidation, finishVisitInfo, t]
  );

  const templateValidation = useMemo(
    () => new Validation(DocumentTemplateSchema),
    []
  );

  const templateValidationErrors = useMemo(
    () => templateValidation.validate(completingInformation, t),
    [templateValidation, completingInformation, t]
  );

  const hasAdditionalEmailAddressesProblem =
    finishVisitInfo.sendByEmail && pendingAdditionalEmailAddressIsInvalid;

  const hasAnyError =
    finishVisitInfoValidationErrors.length > 0 ||
    noEmailAddressesSpecified ||
    isAttachmentTotalSizeToLarge ||
    hasAdditionalEmailAddressesProblem ||
    completingInformationErrors.length > 0 ||
    templateValidationErrors.length > 0 ||
    dateErrors.unallowed2024creation;

  const isOnline = useIsOnline();
  const canFinish = isOnline && !hasAnyError;

  return (
    <div className="d-flex flex-column overflow-auto">
      <div className="flex-grow-0 flex-shrink-0 mt-3">
        <Tabs
          selected={selectedTab}
          justified
          rounded
          tabsSquare
          className="bg-normal"
          onSelect={(newFinishTabSection) =>
            newFinishTabSection !== selectedTab &&
            setSelectedTab(newFinishTabSection)
          }
        >
          <Tab
            id="completingInformation"
            headerText={t("completingInformation")}
            warning={completingInformationErrors.length > 0}
          />
          <Tab
            id="protocolAndEmail"
            headerText={t("protocolAndEmail")}
            warning={hasAnyError}
          />
        </Tabs>
      </div>
      <div>
        {selectedTab === "completingInformation" && (
          <CompletingInformationTab
            validationErrors={completingInformationErrors}
          />
        )}
        {selectedTab === "protocolAndEmail" && (
          <CompletingInformationProvider>
            <FinishVisitTab
              validationErrors={finishVisitInfoValidationErrors}
              templateValidationErrors={templateValidationErrors}
              hasCompletingInfoValidationErrors={
                completingInformationErrors.length > 0
              }
              noEmailAddressesSpecified={noEmailAddressesSpecified}
              pendingAdditionalEmailAddressIsInvalid={
                pendingAdditionalEmailAddressIsInvalid
              }
              canFinish={canFinish}
            />
          </CompletingInformationProvider>
        )}
      </div>
    </div>
  );
}

export function FinishTab() {
  // Since the _pending_ value in the additional email addresses fields is used to determine if it's possible to finish
  // the visit or not, its internal state has to be hoisted all the way up here
  const {finishVisitInfo} = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();

  return (
    <InputWithTagsProvider
      value={finishVisitInfo.emailInfo.sendToAdditionalEmailAddresses ?? []}
      onChange={(sendToAdditionalEmailAddresses) =>
        merge({
          finishVisitInfo: {
            ...finishVisitInfo,
            emailInfo: {
              ...finishVisitInfo.emailInfo,
              sendToAdditionalEmailAddresses,
            },
          },
        })
      }
      validator={(x) => isValidEmailAddress(x)}
      separator={","}
    >
      {(pendingAdditionalEmailAddressIsInvalid) => (
        <FinishTabInternal
          pendingAdditionalEmailAddressIsInvalid={
            pendingAdditionalEmailAddressIsInvalid
          }
        />
      )}
    </InputWithTagsProvider>
  );
}
